import React from "react"
import Layout from "../../components/Layout"
import colors from "../../colors"
import { getAlbums, Album } from "../../data/albums"
import AlbumCover from "../../components/AlbumCover"
import AlbumText from "../../components/AlbumText"
import Title from "../../components/Title"
import styled from "styled-components"
import { getPerson } from "../../data/people"

const Albums = styled.div``
const AlbumWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  max-width: 90%;
`
const CoverWrapper = styled.div`
  flex: 0 0 200px;
`
const AlbumInfo = styled.div`
  flex: 1 0 auto;
  padding: 20px 40px; 0 0;
`
const AlbumName = styled.h3``
const ReleaseDate = styled.div``
const PeopleWrapper = styled.div``

const AlbumSummary = ({ album }: { album: Album }) => {
  const people = album.people.map(getPerson)
  const lastPerson = people.pop()
  return (
    <AlbumWrapper>
      <CoverWrapper>
        <a href={`/press/${album.id}`}>
          <AlbumCover
            small
            filename={album.coverImage}
            borderColor={colors.black}
          />
        </a>
      </CoverWrapper>
      <AlbumInfo>
        <a href={`/press/${album.id}`}>
          <AlbumName>{album.name}</AlbumName>
        </a>
        <ReleaseDate>{album.releaseDate}</ReleaseDate>

        <PeopleWrapper>
          {people.map(p => (
            <span key={p.name}>{p.name}, </span>
          ))}{" "}
          and <span>{lastPerson.name}</span>.
        </PeopleWrapper>
      </AlbumInfo>
    </AlbumWrapper>
  )
}

export default () => (
  <Layout backgroundColor={colors.white} textColor={colors.problem.darkPurple}>
    <Title headroom color={colors.problem.purple}>
      THE WIG OUT
    </Title>
    <AlbumText>
      <p>
        The Wig Out was formed by Dave Foley (
        <a href="https://open.spotify.com/artist/24i0ANivdxrCXdFfRbKfKc?si=pVbYx-OpRmeeaicPS0Ozng">
          Hypatia Lake{" "}
        </a>
        /Seattle, WA) and Adam Pearson (
        <a href="https://soundcloud.com/swift-tuttle">Swift-Tuttle</a>/Olympia,
        WA) in March of 2020. Inspired by the constraints of creative games like
        Exquisite Corpse, we came up with a unique approach to creating music
        within our physically separated reality (see below for more details).
      </p>
      <p>
        Once we figured out the basics, we invited friends and bandmates from
        Seattle, Boston, San Francisco, and New Zealand to join us and try it
        out.
      </p>
      <p>
        As one might expect when creating albums with everchanging lineups,
        there is a lot of variety on each album. As we've become accustomed to
        the process, thematic and sonic continuity has begun to emerge, both
        within each album, and across the entire project.
      </p>
    </AlbumText>
    <Title headroom color={colors.problem.purple}>
      Discography
    </Title>
    <Albums>
      {getAlbums().map(album => (
        <AlbumSummary album={album} key={album.id} />
      ))}
    </Albums>
    <Title headroom color={colors.problem.purple}>
      The Process
    </Title>
    <AlbumText>
      <p>Here's a bit more detail for the nerdy. </p>
      <p>
        Each album has as many songs as it does musicians. At the beginning of
        each album, each member supplies a song title. Those titles are shuffled
        and assigned to a different person to work on each week for four weeks.
      </p>
      <p>
        Each week, at a designated time, we have an online listening party where
        each member presents the song they worked on that week then we discuss
        it. The only rules are that we go through the songs alphabetically by
        title and that it is forbidden to talk about the song until after we all
        listen to it.
      </p>
      <p>
        Each member then uploads the stems (audio files of the same length) for
        the song to dropbox, where they are picked up by the next person.
      </p>
      <p>
        We release a new album every three weeks, with a new set of musicians.
        Most of the time we have two albums in the works simultaneously, with
        different lineups and overlapping schedules.
      </p>
      <p>
        ...and that's pretty much it! Send us an email if you have any
        questions.
      </p>
    </AlbumText>
  </Layout>
)
