const colors = {
  transparent: "#0000",
  yellow: "#FFFA52",
  cyan: "#40A2F7",
  white: "white",
  black: "black",
  pink: "#ff69ba",
  bakedDwarves: {
    pink: "#ff69ba",
    cyan: "#40A2F7",
    yellow: "#FFFA52",
  },
  numberTwo: {
    orange: "rgb(231,136,60)",
    yellow: "rgb(250,223,75)",
  },
  problem: {
    pink: "#C89098",
    purple: "#43246A",
    darkPurple: "#26133D",
  },
  quintessence: {
    yellow: "rgb(238,206,79)",
  },
  getsEven: {
    red: "rgb(255,0,0)",
    green: "rgb(0, 255, 0)",
    cyan: "rgb(0, 196, 195)",
    magenta: "rgb(213, 0, 201)",
    yellow: "rgb(197, 195, 0)",
    pink: "rgb(255, 98, 105)",
  },
  kyjas: {
    orangeish: "rgb(235, 178, 44)",
    greenish: "rgb(31, 102, 101)",
  },
}

export default colors
